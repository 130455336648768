import auth0 from 'auth0-js';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./types";

//TODO: Handle Custom Authentication use auth0-js 
// https://auth0.github.io/auth0.js/WebAuth.html#login
// https://www.youtube.com/watch?v=qtHwLH_P2ZY
export const getAuth0Client = async () => {
    return new auth0.WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENT_ID,
        redirectUri: window.location.origin + "/callback",
        audience: AUTH0_AUDIENCE,
        responseType: 'token id_token',
    })
}
