export interface Lead {
    id: number;
    lead_id: string;
    integration_id: number;
    field_name: string;
    field_value: string;
    form_id: string;
    form_name: string;
    isSync: boolean;
    created_at: string;
    updated_at: string;
}

export interface Integration {
    id: number;
    name: string;
    source: string;
    destination: string;
    leads_count: number;
    is_live: boolean;
    created_at: string;
    user_type: string;
}

export const BASE_URL = process.env.REACT_APP_SERVER_API_URL ? `${process.env.REACT_APP_SERVER_API_URL}/api/v1` : 'http://localhost:8000/api/v1';
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : '';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ? process.env.REACT_APP_AUTH0_AUDIENCE : '';
