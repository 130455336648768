import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import "../styles.css"
import { getAuth0Client } from '../../../custom-auth0';
import { Link, useNavigate } from 'react-router-dom';


const SignUp = () => {
    const { loginWithRedirect } = useAuth0();
    const [error, setError] = useState<string | null>(null);
    const [signUpData, setSignUpData] = useState({
        email: "",
        password: "",
        first_name: "",
        family_name: ""
    });
    const [loading, setLoading] = useState(false);

    const handleGoogleSignUp = () => {
        loginWithRedirect({
            authorizationParams: { connection: 'google-oauth2' }
        });
    };

    const handleFacebookSignUp = () => {
        loginWithRedirect({
            authorizationParams: { connection: 'facebook' }
        });
    }

    const handleCustomSignUp = async () => {
        const auth0Client = await getAuth0Client()
        setLoading(true);
        try {
          const signupPayload =  {
            email: signUpData.email,
            password: signUpData.password,
            connection: "Username-Password-Authentication"
          }
            auth0Client.signup(signupPayload, (err, authResult) => {
                if (err) {
                    console.error(err);
                    setError(err.description || "Error signing up");
                    setLoading(false);
                }
                if (authResult && authResult.idToken) {
                    console.log("Signup successful: ", authResult);
                    setLoading(false);
                }
            });
        } catch (error) {
            console.error("Signup error: ", error);
            setError("Error signing up");
            setLoading(false);
        }
    }

    const handleSignUpInput = (e: any) => {
        console.log("Signup input: ", signUpData);
        setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
    }

    return (
      <section className="h-screen flex overflow-hidden">
          <div className="container mx-auto px-4">
              <div className="flex items-center justify-center h-full lg:justify-between">

                  <div className="hidden lg:block lg:w-1/2 xl:w-6/12">
                      <img
                          src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                          className="w-full h-full object-cover"
                          alt="Sample"
                      />
                  </div>

                  {/* Form container */}
                  <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
                      <div className="w-full flex justify-center text-center">
                          <div>
                              <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-300">
                                  Welcome to OmnifyX
                              </h1>
                              <p className="text-gray-500 dark:text-gray-400">
                                  Create an account to continue
                              </p>
                          </div>

                      </div>
                      <div className="w-full lg:max-w-md p-6 bg-white rounded-lg shadow-xl">
                          {error && (
                              <div className="text-center mb-4 text-lg text-red-600">
                                  {error}
                              </div>
                          )}
                          <div className="max-w-md mx-auto">
                              {/* Email Input */}
                              <div className="mb-4">
                                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                      Email address
                                  </label>
                                  <input
                                      onChange={handleSignUpInput}
                                      name="email"
                                      type="email"
                                      id="email"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                      placeholder="name@example.com"
                                      required
                                  />
                              </div>
                              <div className="mb-6">
                                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                      Password
                                  </label>
                                  <input
                                      onChange={handleSignUpInput}
                                      name="password"
                                      type="password"
                                      id="password"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                      required
                                  />
                              </div>
                              <div className="mb-6">
                                  <label htmlFor="passwordConfirm" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                     Confirm Password
                                  </label>
                                  <input
                                      onChange={handleSignUpInput}
                                      name="passwordConfirm"
                                      type="password"
                                      id="passwordConfirm"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                      required
                                  />
                              </div>
                              <div className="mb-6 text-sm">
                                <span className="mr-2">Already have an account?</span>
                                  <Link to={"/signin"}>
                                  Sign In
                                  </Link>
                              </div>

                              <button
                                  onClick={handleCustomSignUp}
                                  disabled={!signUpData.email || !signUpData.password}
                                  type="button"
                                  className={` ${!signUpData.email || !signUpData.password ? "cursor-not-allowed opacity-50": ""} text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center`}
                              >
                                  {loading ? "Signing Up..." : "Sign Up"}
                              </button>

                              <div className="mt-4 flex items-center justify-between">
                                  <span className="w-1/4 border-t border-gray-300"></span>
                                  <span className="text-xs text-center text-gray-500 uppercase px-2">or continue with</span>
                                  <span className="w-1/4 border-t border-gray-300"></span>
                              </div>
                              <div className="flex flex-col space-y-4 mt-4">
                                  {/* Google Button */}
                                  <button
                                      onClick={handleGoogleSignUp}
                                      className="gsi-material-button block mt-4 w-[400px] px-8 py-2 rounded-md text-black text-center font-bold"
                                  >
                                      <div className="gsi-material-button-content-wrapper">
                                          <div className="gsi-material-button-icon">
                                              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
                                                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                  <path fill="none" d="M0 0h48v48H0z"></path>
                                              </svg>
                                          </div>
                                          <span className="gsi-material-button-contents">Sign up with Google</span>
                                          <span style={{ display: 'none' }}>Sign up with Google</span>
                                      </div>
                                  </button>
                                  <button
                                      onClick={handleFacebookSignUp}
                                      type="button"
                                      className="py-2 flex justify-center items-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                  >
                                      <svg
                                          width="20"
                                          height="20"
                                          fill="currentColor"
                                          className="mr-2"
                                          viewBox="0 0 1792 1792"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-hidden="true"  // Added for accessibility
                                      >
                                          <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
                                      </svg>
                                      Sign up with Facebook
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
};

export default SignUp;
