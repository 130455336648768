import React from "react";
import { useGetIntegrationMappingsQuery } from "../../services/mappings";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useTestMappingMutation } from "../../services/integrations";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";


const TestMappingForm = () => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [formValues, setFormValues] = React.useState({});
    const sourceFormValues = useSelector((state: RootState) => state.integrationsReducer.sourceFormValues);
    const { data: mappings } = useGetIntegrationMappingsQuery(sourceFormValues.integration_id)
    const [testMappings, { isLoading: testLoading }] = useTestMappingMutation({})
    const [testSucces, setTestSuccess] = React.useState(false);
    const navigate = useNavigate();

    const handleTestButtonClick = () => {
        if (Object.values(formValues).includes("")) {
            setErrorMessage("Please fill all the fields");
            return;
        }
        const simulatedStructure = {
            data: [
                {
                    created_time: new Date().toISOString(),
                    id: "984046359280067",  // This can be a dynamic ID if needed
                    field_data: Object.entries(formValues).map(([key, value]) => ({
                        name: key,
                        values: [value]
                    }))
                }
            ],
            paging: {
                cursors: {
                    before: "esoewiejor",
                    after: "weeoprkepwe"
                }
            }
        };
        console.log(simulatedStructure);
        testMappings({ integration_id: sourceFormValues.integration_id, payload: simulatedStructure }).unwrap().then((data) => {
            console.log(data);
            setTestSuccess(true);
            setErrorMessage("");
        }).catch((err: any) => { 
            console.log(err); 
            setErrorMessage("Something went wrong. Fix mapping and retry")}
        )
    }

    const handleBacktoHome = () => {
        navigate('/dashboard');
    }
    const backToMappings = () => {
        navigate(`/integrations/${sourceFormValues.integration_id}/mappings`);
    }

    if (testLoading) {
        return (
            <div className="flex justify-center items-center h-[100vh]">
                <Loading />
            </div>
        )
    }
    return (
        <div className="container mt-4">
            {testSucces && <div className="text-green-500 text-center text-lg semi-bold">Test Successful</div>}
            {errorMessage && <div className="text-red-500 text-center text-lg semi-bold">{errorMessage}</div>}
            <div className="flex justify-center items-center">
                <div className="flex flex-col w-1/2 ">
                    {mappings?.map((mapping: any) => {
                        return (
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col w-full mb-4">
                                    <label className="text-xl font-bold">{mapping.source_field}</label>
                                    <input
                                        onChange={(e) => setFormValues({ ...formValues, [mapping.source_field_key]: e.target.value })}
                                        type="text"
                                        name={mapping.source_field_key}
                                        className="border-2 border-gray-300 rounded-md p-2"
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex justify-center items-center">
                        <button onClick={() => handleTestButtonClick()} className="bg-blue-500 text-white rounded-md p-2 w-1/2 mt-2 mr-2">Test</button>
                        {!testSucces ? <button onClick={() => backToMappings()} className="bg-blue-500 text-white rounded-md p-2 w-1/2 mt-2">Back to Mappings</button> : <button onClick={() => handleBacktoHome()} className="bg-blue-500 text-white rounded-md p-2 w-1/2 mt-2">Back to Home</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestMappingForm;
