import { api } from "./api";

const mappingsApi = api.injectEndpoints({
    endpoints: (builder) => ({
      getIntegrationMappings: builder.query({
        query: (integrationId) => `/mappings/${integrationId}`,
        providesTags: (result, error, arg) => [{ type: 'Mappings', id: arg }],
      }),
      deleteMapping: builder.mutation({
        query: (mapping_id) => ({
          url: `/mappings/${mapping_id}`,
          method: "DELETE",
        }),
        invalidatesTags: [{ type: 'Mappings' }],
      }),
      savingMapping: builder.mutation({
        query: (data) => ({
          url: `/mappings`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: [{ type: 'Mappings' }],
      }),
    }),
    overrideExisting: false,
  });
  
export const {
  useGetIntegrationMappingsQuery,
  useSavingMappingMutation,
  useDeleteMappingMutation,
} = mappingsApi;
