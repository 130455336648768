import { api } from "./api";


const chartApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCharts: builder.query({
      query: () => `/charts`,
    }),
    getChart: builder.query({
      query: (chartId) => `/charts/${chartId}`,
    }),
    getChartImage: builder.query({
      query: (payload) => ({
        url: `/charts/${payload.chartId}/image?values=${payload.values}`,
        method: "GET",
        responseHandler: (response) => response.blob().then(
          (blob) => URL.createObjectURL(blob)
        )
      }),
    }),
    createChart: builder.mutation({
      query: (data) => ({
        url: `/charts`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Charts" }],
    }),
    updateChart: builder.mutation({
      query: (data) => ({
        url: `/charts/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "Charts" }],
    }),
    deleteChart: builder.mutation({
      query: (chartId) => ({
        url: `/charts/${chartId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Charts" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetChartsQuery,
  useGetChartQuery,
  useGetChartImageQuery,
  useCreateChartMutation,
  useUpdateChartMutation,
  useDeleteChartMutation,
} = chartApi;
