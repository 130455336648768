import React from "react";

import OxButton from "../../components/Ui/button";
import { useNavigate } from "react-router-dom";
import { 
  useGetIntegrationsQuery, 
} from "../../services/integrations";
import IntegrationsTable from "../../components/Integrations/Table";

const Home = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate('/integrations/new');
    }

    const handleInviteCollaborator = () => {
        navigate('/integrations/invite');
    }

    const { data, isFetching: isIntegrationFetching, refetch} = useGetIntegrationsQuery({});

    return (
        <div className="container mt-4 mx-auto">
            <div className="flex justify-between flex-row">
                <div className="text-3xl">
                    Integrations
                </div>
                <div className="flex justify-between">
                    <OxButton value={"Create New Integration"} onClickHandler={handleButtonClick} />
                    {data && data.length > 0 && <OxButton value={"Invite Colaborator"} onClickHandler={handleInviteCollaborator} />}
                </div>
            </div>
            <hr className="h-px my-2 bg-gray-200 border-0" />
            <IntegrationsTable isIntegrationFetching={isIntegrationFetching} integrations={data} refetchIntegrations={refetch} />
        </div>
    )
}
export default Home;