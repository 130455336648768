import { api } from "./api";

const audienceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAudiences: builder.query({
      query: () => `/audiences`,
    }),
    getAudience: builder.query({
      query: (audienceId) => `/audiences/${audienceId}`,
    }),
    createAudience: builder.mutation({
      query: (data) => ({
        url: `/audiences`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Audiences" }],
    }),
    addUserToAudience: builder.mutation({
      query: (data) => ({
        url: `/audiences/${data.id}/mediator`,
        method: "POST",
        body: data.payload,
      }),
    }),
    updateAudience: builder.mutation({
      query: (data) => ({
        url: `/audiences/${data.id}`,
        method: "PATCH",
        body: data.payload,
      }),
      invalidatesTags: [{ type: "Audiences" }],
    }),
    deleteAudience: builder.mutation({
      query: (audienceId) => ({
        url: `/audiences/${audienceId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Audiences" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAudiencesQuery,
  useGetAudienceQuery,
  useCreateAudienceMutation,
  useUpdateAudienceMutation,
  useDeleteAudienceMutation,
  useAddUserToAudienceMutation,
} = audienceApi;
