import React, { useEffect } from "react";
import ACSDestinationForm from "./DestinationForm";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckExistingAcsTokenQuery, useCreateDestinationIntegrationMutation } from "../../services/integrations";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

import OxButton from "../../components/Ui/button";
import Loading from "../../components/loading";



const AddDestination = () => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const { id } = useParams<{ id: string }>();

    const [createDestinationIntegration, { isLoading: isLoadingCreateDestination }] = useCreateDestinationIntegrationMutation()
    const { data, isFetching: isFetchingExistingDestination } = useCheckExistingAcsTokenQuery({});
    const user_id = useSelector((state: RootState) => state.usersReducer.user_id);
    const [createDestination, setCreateDestination] = React.useState(false);
    console.log(data)
    const [destinationFormState, setDestinationFormState] = React.useState({
        acs_client_id: "",
        acs_client_secret: "",
        acs_tenant_id: "",
        user_id: user_id
    });

    console.log(id);
    const navigate = useNavigate();


    const validateDestinationForm = () => {
        if (destinationFormState.acs_client_id === "") {
            setErrorMessage("ACS Client ID is required");
            return false;
        }
        if (destinationFormState.acs_client_secret === "") {
            setErrorMessage("ACS Client Secret is required");
            return false;
        }
        if (destinationFormState.acs_tenant_id === "") {
            setErrorMessage("ACS Tenant ID is required");
            return false;
        }
        return true;
    }

    const handleSubmitDestination = () => {
        if (!validateDestinationForm()) {
            return;
        }
        const destinationPayload = {
            ...destinationFormState,
            integration_id: id
        }
        createDestinationIntegration(destinationPayload).unwrap().then((data: any) => {
            setErrorMessage("");
            navigate(`/integrations/${id}/mappings`);
        }).catch((err) => {
            switch (err.status) {
                case 401:
                    setErrorMessage(err.data.detail);
                    break;
                case 422:
                    setErrorMessage("Invalid data");
                    break;
                case 500:
                    setErrorMessage("Something went wrong. Could not create destination! Try again later");
                    break;
                default:
                    break;
            }
        });
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDestinationFormState({
            ...destinationFormState,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (data?.token_exists) {
            setCreateDestination(false);
        } else {
            setCreateDestination(true);
        }
    }, [data])


    if (isLoadingCreateDestination || isFetchingExistingDestination) {
        return (
            <div className="flex justify-center items-center h-[100vh]">
                <Loading />
            </div>
        )
    }



    return (
        <div>
            {data && data?.token_exists && createDestination === false ? null : <h1 className="text-center text-2xl font-bold my-4">Add Destination</h1>}

            {errorMessage && <div className="flex text-center mb-4 justify-center text-red-500 text-xl">*{errorMessage}!</div>}

            {data && data?.token_exists && createDestination === false ?
                <div className="flex justify-center items-center mt-4">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold">Destination already exists</h1>
                        <p className="text-lg">You have already added a destination</p>
                        <div className="flex justify-center mt-4">
                            <OxButton value="Go to Mappings" onClickHandler={() => navigate(`/integrations/${id}/mappings`)} />
                            <OxButton value="Update Existing Credentials" onClickHandler={() => setCreateDestination(true)} />
                        </div>
                    </div>
                </div>
                :
                <>
                    <ACSDestinationForm handleChange={onChangeHandler} />
                    <div className="flex justify-center mt-4">
                        <OxButton onClickHandler={handleSubmitDestination} value={data?.token_exists ? "Update Credentials" : "Add Credentials"} />
                    </div>
                </>
            }
        </div>
    );
}
export default AddDestination;