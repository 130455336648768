import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Ensure you have axios installed
import { useAddUserToAudienceMutation } from '../../services/audiences';
import OxButton from '../../components/Ui/button';
import { env } from 'process';

// Define interfaces for the audience and payload
interface Audience {
  id: string;
  name: string;
}

interface LocationState {
  state: {
    audience: Audience;
  };
}

interface PayloadItem {
  EMAIL: string;
  FN: string;
}

const Mediator: React.FC = () => {
    const { state } = useLocation() as LocationState;
    const { audience } = state;
    const [payload, setPayload] = useState<PayloadItem[]>([{ EMAIL: '', FN: '' }]);
    const [response, setResponse] = useState<string>('');
    const [addAudience] = useAddUserToAudienceMutation();
    const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const values = [...payload];
        values[index][event.target.name as keyof PayloadItem] = event.target.value;
        setPayload(values);
    };
    const mediator_url = `${process.env.REACT_APP_SERVER_API_URL}/${audience.id}/mediator`
    const navigate = useNavigate();
    const [submitBtnText, setSubmitBtnText] = useState<string>('Submit');

    const handleAddFields = () => {
        setPayload([...payload, { EMAIL: '', FN: '' }]);
    };

    const handleRemoveFields = (index: number) => {
        const values = [...payload];
        values.splice(index, 1);
        setPayload(values);
    };

    const handleSubmit = () => {
        setSubmitBtnText('Submitting...');
        const data = {
            payload: { data: payload},
            id: audience.id
        }
        addAudience(data).unwrap().then((response: any) => {
            setResponse(JSON.stringify(response, null, 2));
            setSubmitBtnText('Submit');
        }).catch((error: any) => {
            setResponse(JSON.stringify(error?.response?.data, null, 2));
            setSubmitBtnText('Submit');
        });
    };

    const handleCreateAudience = () => {
        console.log('Create Audience');
        navigate('/audiences/new');
    }

    return (
        <>
            <div className="flex justify-between flex-wrap md:flex-nowrap items-center pt-3 pb-2 mb-3 border-b mx-4">
                <h1 className="text-2xl font-bold">{audience.name}</h1>
                <div className="flex items-center space-x-2">
                    <OxButton value="Create New Custom Audience"  onClickHandler={handleCreateAudience} />
                </div>
            </div>
            {/* <div className="flex flex-col items-center">
                <div className="mb-4"><strong>Add users to "{audience.name}" Facebook audience:</strong></div>
                {payload.map((inputField, index) => (
                    <div key={index} className="flex space-x-3 mb-3">
                        <input
                            type="email"
                            name="EMAIL"
                            value={inputField.EMAIL}
                            onChange={event => handleChange(index, event)}
                            placeholder="Email"
                            required
                            className="input"
                        />
                        <input
                            type="text"
                            name="FN"
                            value={inputField.FN}
                            onChange={event => handleChange(index, event)}
                            placeholder="First Name"
                            required
                            className="input"
                        />
                        <button type="button" onClick={() => handleRemoveFields(index)} className="btn btn-danger">Remove</button>
                    </div>
                ))}
                <button type="button" onClick={handleAddFields} className="btn btn-secondary mb-3">Add More</button>
                <OxButton value={submitBtnText} onClickHandler={handleSubmit} />
            </div> */}
            <div className="mt-4 mx-4 text-center">
                <h1 className="text-xl font-bold">Mediator URL: {mediator_url}</h1>
            </div>
            {/* <div className="mt-4 mx-4">
                <h1 className="text-2xl font-bold">Facebook Response:</h1>
                <pre>{response}</pre>
            </div> */}
        </>
    );
};

export default Mediator;
