import React, { useState } from "react";
import OxButton from "../../components/Ui/button";
import { useCreateChartMutation, useGetChartQuery, useUpdateChartMutation } from "../../services/charts";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import { toast } from 'react-toastify';


const ChartForm = () => {
    const { id } = useParams()
    const [createChart, { isLoading: isCreating, isSuccess: isCreateSuccess, isError: isCreateError }] = useCreateChartMutation();
    const [updateChart, { isLoading: isUpdating, isSuccess: isUpdateSuccess, isError: isUpdateError }] = useUpdateChartMutation();

    const { data: chartInfo, isFetching: chartInfoFetching } = useGetChartQuery(id);

    const [chartFormState, setChartFormState] = React.useState({
        name: chartInfo?.name,
        color: chartInfo?.color,
        value_format: chartInfo?.value_format,
        labels: chartInfo?.labels,
    });

    const [chartType, setChartType] = useState(chartInfo?.kind)
    const [valueFormat, setValueFormat] = useState(chartInfo?.value_format)
    const [background, setBackground] = useState(chartInfo?.background)
    const [showBarValue, setShowBarValue] = useState(chartInfo?.show_bar_value === "true" ? true : false)
    const [displayTitle, setDisplayTitle] = useState(chartInfo?.display_chart_title === "true" ? true : false)

    // Inputs Error Messages
    const [chartNameError, setChartNameError] = useState("");
    const [chartTypeError, setChartTypeError] = useState("");
    const [backgroundError, setBackgroundError] = useState("");
    const [valueFormatError, setValueFormatError] = useState("");
    const [colorError, setColorError] = useState("");
    const [labelsError, setLabelsError] = useState("");
    const [showBarValueError, setShowBarValueError] = useState("");


    const onFormFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChartFormState({
            ...chartFormState,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "name") {
            setChartNameError("")
        }
        if (e.target.name === "color") {
            setColorError("")
        }
        if (e.target.name === "labels") {
            setLabelsError("")
        }
    }

    const handleChartTypeChange = (e: any) => {
        setChartType(e.target.value)
        setChartTypeError("")
    }

    const handleValueFormatChange = (e: any) => {
        setValueFormat(e.target.value)
        setValueFormatError("")
    }

    const handleBackgroundChange = (e: any) => {
        setBackground(e.target.value)
        setBackgroundError("")
    }

    const handleUpdateChart = () => {
        const payload = {
            ...chartFormState,
            background: background,
            kind: chartType,
            value_format: valueFormat,
            show_bar_value: showBarValue,
            display_chart_title: displayTitle,
            id: chartInfo?.id
        }
        updateChart(payload).unwrap().then((data: any) => {
            setChartFormState({
                name: "",
                color: "",
                value_format: "",
                labels: "",
            });
        }).catch((err) => {
            console.log(err)
        }
        );
    }

    const handleCreateChart = () => {
        if (chartFormState.name === "" || chartFormState.name === undefined) {
            setChartNameError("Please enter a name")
            return
        }
        if (chartType === "" || chartType === undefined) {
            setChartTypeError("Please select a chart type")
            return
        }
        if (background === "" || background === undefined) {
            setBackgroundError("Please select a background")
            return
        }
        if (valueFormat === "" || valueFormat === undefined) {
            setValueFormatError("Please select a value format")
            return
        }
        if (chartFormState.color === "" || chartFormState.color === undefined) {
            setColorError("Please enter a color code in the format #AABBCC")
            return
        }
        if (chartFormState.color && !/^#[0-9A-Fa-f]{6}$/.test(chartFormState.color)) {
            setColorError("Please enter a valid color code in the format #AABBCC")
            return
        }
        if (chartFormState.labels === "" || chartFormState.labels === undefined) {
            setLabelsError("Please enter labels for the chart. example: label1, label2, label3")
            return
        }

        if (chartFormState.labels && chartFormState.labels.split(",").length < 2) {
            setLabelsError("Please enter at least two labels for the chart")
            return
        }

        if (chartFormState.labels && chartFormState.labels.split(",").length > 10) {
            setLabelsError("You can only enter a maximum of 10 labels for the chart")
            return
        }



        const payload = {
            ...chartFormState,
            background: background,
            kind: chartType,
            value_format: valueFormat,
            show_bar_value: showBarValue,
            display_chart_title: displayTitle
        }
        createChart(payload).unwrap().then((data: any) => {
            setChartFormState({
                name: "",
                color: "",
                value_format: "",
                labels: "",
            });
        }).catch((err) => {
            console.log(err)
        }
        );
    }

    if (isUpdateError) {
        toast.error('Could not update chart. Try again later');
    }

    if (isUpdateSuccess) {
        toast.success('Chart updated successfully');
    }

    if (isCreateError) {
        toast.error('Could not create chart. Try again later');
    }

    if (isCreateSuccess) {
        toast.success('Chart created successfully');
    }
    if (chartInfoFetching || isCreating || isUpdating) {
        return <div className="flex justify-center items-center h-[100vh]">
            <Loading />
        </div>
    }

    return (
        <div className="mt-4">
            <h4 className="text-center">{chartInfo ? "Update Chart" : "Create Chart"}</h4>
            <div className="flex flex-col  items-center w-full">
                <div className="flex flex-col w-1/2 relative">
                    <div>
                        <label htmlFor="name">Chart Name</label>
                        <input type="text" id="name" name="name" value={chartFormState.name} className="form-control" onChange={onFormFieldChange} required />
                    </div>
                    {chartNameError && <span className="text-left text-red-500 absolute top-8 bottom-0 mt-8">*{chartNameError}</span>}
                </div>
                <div className="flex flex-col w-1/2 mt-4 relative">
                    <div>
                        <label htmlFor="kind">Type</label>
                        <select id="kind" name="kind" className="form-control" value={chartType} onChange={handleChartTypeChange}>
                            <option value="">Select Chart Type</option>
                            <option value="bar" defaultChecked>Bar</option>
                        </select>
                    </div>
                    {chartTypeError && <span className="text-red-500 top-8 absolute mt-8 bottom-0">*{chartTypeError}</span>}
                </div>
                <div className="flex flex-col w-1/2 mt-4 relative">
                    <div>
                        <label htmlFor="background">Background</label>
                        <select id="background" name="background" className="form-control" value={background} onChange={handleBackgroundChange}>
                            <option value="">Select Background</option>
                            <option value="white">White</option>
                            <option value="transparent">Transparent</option>
                        </select>
                    </div>
                    {backgroundError && <span className="text-red-500 top-8 mt-8 bottom-0 absolute">{backgroundError}</span>}
                </div>
                <div className="flex flex-col w-1/2 mt-4 relative">
                    <div>
                        <label htmlFor="color">Color</label>
                        <input
                            type="text"
                            pattern="#[0-9A-Fa-f]{6}"
                            placeholder="Hex color code (e.g. #AABBCC, #123456, #12AB3F etc.)"
                            id="color"
                            name="color"
                            className="form-control"
                            value={chartFormState.color}
                            onChange={onFormFieldChange} />
                    </div>
                    {colorError && <span className="text-red-500 absolute bottom-0 top-8 mt-8">*{colorError}</span>}
                </div>
                <div className="flex flex-col w-1/2 mt-4 relative">
                    <div>
                        <label htmlFor="labels">Labels</label>
                        <input type="text" id="labels" name="labels" className="form-control" value={chartFormState.labels} onChange={onFormFieldChange} />
                        <small className="form-text text-muted">
                            Labels must be comma separated.
                        </small>
                    </div>
                    {labelsError && <span className="text-red-500 absolute bottom-0 top-8 mt-12">*{labelsError}</span>}
                </div>
                <div className="flex flex-col w-1/2 mt-4 relative">
                    <div>
                        <label htmlFor="data">Value Format</label>
                        <select id="format" name="value_format" className="form-control" value={valueFormat} onChange={handleValueFormatChange} >
                            <option value="">Select Value Format</option>
                            <option value="none">Not Formatted</option>
                            <option value="money">$ Money</option>
                        </select>
                    </div>
                    {valueFormatError && <span className="text-red-500 absolute bottom-0 top-8 mt-8">*{valueFormatError}</span>}
                </div>
                <div className="flex flex-col w-1/2 mt-4 relative">
                    <div className="form-check ">
                        <label className="form-check-label">
                            Display Title
                        </label>
                        <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDisplayTitle(e.target.checked)
                        }} className="form-check-input" id="display_chart_title" name="display_chart_title" type="checkbox" checked={displayTitle} />
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            Show Bar Value
                        </label>
                        <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setShowBarValue(e.target.checked)
                        }} className="form-check-input" type="checkbox" id="show_bar_value" name="show_bar_value" checked={showBarValue} />
                    </div>
                </div>
                <div className="mt-4">
                    <OxButton value={chartInfo ? "Update" : "Create New Chart"} onClickHandler={chartInfo ? handleUpdateChart : handleCreateChart} />
                </div>
            </div>
        </div>
    )
}
export default ChartForm;