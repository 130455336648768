import React from "react";
import OxButton from "../../components/Ui/button";
import { useCreateAudienceMutation, useGetAudienceQuery, useUpdateAudienceMutation } from "../../services/audiences";
import { useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';


const TermsOfServiceAlert = ({business_id}: any) => {
    return (
      <div>
        Terms of service is not accepted Here.<br />
        Please visit: <a href={`https://business.facebook.com/ads/manage/customaudiences/tos?act=${business_id}`} target="_blank" rel="noopener noreferrer">https://business.facebook.com/ads/manage/customaudiences/tos?act=${business_id}</a>
      </div>
    );
  }
  


const AudienceForm = () => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [createAudience, { isLoading: isLoadingCreateAudience, isSuccess: createAudienceSuccessful }] = useCreateAudienceMutation();
    const [updateAudience, { isLoading: isLoadingUpdateAudience, isSuccess: updateAudienceSuccessful }] = useUpdateAudienceMutation();
    const [tos_message, setTosMessage] = React.useState("");

    const { state } = useLocation()
    const audience = state?.audience

    const [submitBtnText, setSubmitBtnText] = React.useState(audience ? "Update Custom Audience" : "Create Custom Audience");
    const [audienceFormState, setAudienceFormState] = React.useState({
        name: audience?.name || "",
        description: audience?.description || "",
        fb_business_id: audience?.fb_business_id || "",
        fb_access_token: ""
    });

    const onFormFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAudienceFormState({
            ...audienceFormState,
            [e.target.name]: e.target.value
        })
    }

    const handleCreateAudience = () => {
        if (audienceFormState.fb_access_token === "") {
            setErrorMessage("Facebook Account Token is required");
            return;
        }

        if (audienceFormState.fb_business_id === "") {
            setErrorMessage("Facebook Ad Account ID is required");
            return;
        }
       setSubmitBtnText("Creating Custom Audience...");
       createAudience(audienceFormState).unwrap().then((data: any) => {
            setErrorMessage("");
            setTosMessage(data.message);
            setSubmitBtnText("Create Custom Audience");
         }).catch((err) => {
                setErrorMessage("Something went wrong. Could not create audience! Try again later");
                console.log(err)
                setSubmitBtnText("Create Custom Audience");
            }
        );
    }

    const handleUpdateAudience = () => {
        if (audienceFormState.fb_access_token === "") {
            setErrorMessage("Facebook Account Token is required");
            return;
        }

        if (audienceFormState.fb_business_id === "") {
            setErrorMessage("Facebook Ad Account ID is required");
            return;
        }
        setSubmitBtnText("Updating Custom Audience...");
        const payload = {
            id: audience?.id,
            payload: audienceFormState
        }
        updateAudience(payload).unwrap().then((data: any) => {
            setErrorMessage("");
            setSubmitBtnText("Update Custom Audience");
        }).catch((err) => {
            setErrorMessage("Something went wrong. Could not update audience! Try again later");
            setSubmitBtnText("Update Custom Audience");
            console.log(err)

        });
    }

    if (updateAudienceSuccessful) {
        toast.success('Audience updated successfully');
    }

    if (createAudienceSuccessful) {
        toast.success('Audience created successfully');
    }


    return (
        <div className="mt-4">
            <h4 className="text-center">Custom Audiences</h4>
            {errorMessage && <div className="text-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{errorMessage}</div>}
            <div className="flex flex-col  items-center w-full">
                <div className="flex flex-col w-1/2">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" className="form-control" onChange={onFormFieldChange} value={audienceFormState.name} />
                </div>
                <div className="flex flex-col w-1/2 mt-4">
                    <label htmlFor="description">Description</label>
                    <input type="text" id="description" name="description" className="form-control" onChange={onFormFieldChange} value={audienceFormState.description} />
                </div>
                <div className="flex flex-col w-1/2 mt-4">
                    <label htmlFor="fb_business_id">Facebook Ad Account ID</label>
                    <input type="text" id="fb_business_id" name="fb_business_id" className="form-control" onChange={onFormFieldChange} value={audienceFormState.fb_business_id} />
                </div>
                <div className="flex flex-col w-1/2 mt-4">
                    <label htmlFor="fb_access_token">Facebook Account Token</label>
                    <input type="text" id="fb_access_token" name="fb_access_token" className="form-control" onChange={onFormFieldChange} />
                </div>
                <div className="mt-4">
                    <OxButton  value={submitBtnText} onClickHandler={audience ? handleUpdateAudience : handleCreateAudience} />
                </div>
                {tos_message && <TermsOfServiceAlert business_id={audienceFormState.fb_business_id}/>}
            </div>
        </div>
    )
}
export default AudienceForm;