import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import axios from "axios";
import AnimatedCheckmark from "../../components/Ui/CheckMark";
import { BASE_URL } from "../../types";


const AcceptInvitation = () => {
    const { token } = useParams<{ token: string }>();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const handleAcceptInvite = async () => {
            setIsLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}/integrations/accept-invitation`, {
                    token: token
                });
                setSuccess(true);
                setSuccessMessage(response.data.message);
            } catch (error) {
                setError(true);
                setErrorMessage("Invalid token");
            }
            setIsLoading(false);
        }
        handleAcceptInvite();
    }, [token]);

    return (
        <div>
            {isLoading && <div className="flex justify-center items-center h-[100vh]">
                <Loading />
            </div>}
            {error && <div className="flex justify-center items-center text-3xl h-[100vh]">{errorMessage}</div>}
            {success && <div className="flex justify-center items-center h-[100vh]">
                <div className="flex flex-col">
                    <div className="flex items-center">
                        <div className="text-3xl text-center">{successMessage}</div>
                        <AnimatedCheckmark />
                    </div>
                    <div className="text-center mr-8">
                        <button
                            onClick={() => navigate("/login")}
                            className="bg-blue-200 hover:bg-blue-300 font-bold py-2 px-4 rounded">
                            Login To Continue
                        </button>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default AcceptInvitation;