import React, { useEffect } from "react";
import { setSourceIntegrationValues } from "../../state/integrations/slice";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState, AppDispatchType } from "../../state/store";
import Loading from "../../components/loading";
import OxButton from "../../components/Ui/button";
import { useCreateSourceIntegrationMutation } from "../../services/integrations";
import { useNavigate } from "react-router-dom";


declare global {
    interface Window {
        FB: any;
    }
}

const ConnectSource = () => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [pages, setPages] = React.useState([]);
    const [pageForms, setPageForms] = React.useState([]);
    const [isFormSelected, setIsFormSelected] = React.useState(false);
    const [isLoggedIntoFacebook, setIsLoggedIntoFacebook] = React.useState(false);
    const [isFacebookDataLoading, setIsFacebookDataLoading] = React.useState(false);
    const [pageLoading, setPageLoading] = React.useState(false);

    const dispatch: AppDispatchType = useAppDispatch();
    const navigate = useNavigate();
    const sourceFormValues = useSelector((state: RootState) => state.integrationsReducer.sourceFormValues);
    const user_id = useSelector((state: RootState) => state.usersReducer.user_id);
    const [createSourceIntegration] = useCreateSourceIntegrationMutation()

    const handleOnPageSelect = (e: any) => {
        setPageLoading(true);
        const pageId = e.target.value.split(',')[0];
        const pageAccessToken = e.target.value.split(',')[1];
        getPageForms(pageId, pageAccessToken);
        dispatch(setSourceIntegrationValues({ page_id: pageId, page_access_token: pageAccessToken }));
        setPageLoading(false);
    }

    const handleOnPageFormSelect = (e: any) => {
        setPageLoading(true);
        const formId = e.target.value.split(',')[0];
        const formName = e.target.value.split(',')[1];
        dispatch(setSourceIntegrationValues({ form_id: formId, form_name: formName }));
        setIsFormSelected(true);
        setPageLoading(false);
    }

    const getPageForms = (page_id: string, page_access_token: string) => {
        window.FB.api(
            '/' + page_id + '/leadgen_forms',
            'get',
            { access_token: page_access_token },
            (response: any) => {
                setPageForms(response.data);
            });
    }

    useEffect(() => {
        setIsFacebookDataLoading(true);
        const checkFacebookLoginStatus = async () => {
            return new Promise((resolve, reject) => {
                window.FB.getLoginStatus((response: any) => {
                    if (response.status === 'connected') {
                        var user_token = window.FB.getAuthResponse()['accessToken']
                        var user_id = window.FB.getAuthResponse()['userID']
                        dispatch(setSourceIntegrationValues({ fb_user_access_token: user_token, fb_user_id: user_id }));
                        fetchFacebookPages(user_token);
                        setIsLoggedIntoFacebook(true);
                        resolve(true);
                        setIsFacebookDataLoading(false);
                    } else {
                        resolve(false);
                        setIsFacebookDataLoading(false);
                    }
                });
            });
        }
        checkFacebookLoginStatus()
    }, [isLoggedIntoFacebook, dispatch]);

    const fetchFacebookPages = (access_token: string) => {
        window.FB.api('/me/accounts?access_token=' + access_token, (response: any) => {
            if (response.data && response.data.length > 0) {
                var pages = response.data;
                setPages(pages);
            }
        });
    }

    const handleFacebookLogin = () => {
        window.FB.login((response: any) => {
            if (response.status === 'connected') {
                var user_token = window.FB.getAuthResponse()['accessToken']
                var user_id = window.FB.getAuthResponse()['userID']
                dispatch(setSourceIntegrationValues({ fb_user_access_token: user_token, fb_user_id: user_id }));
                fetchFacebookPages(user_token);
                setIsLoggedIntoFacebook(true);
            }
        }, {
            scope: 'ads_management,business_management,pages_read_engagement,pages_show_list,pages_manage_ads,pages_manage_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,leads_retrieval,catalog_management'
        });
    };

    const facebookLoginBtn = () => {
        return (
            <div className="flex justify-center items-center h-full">
                {/** Add Facebook Icon */}
                <div className="flex flex-col items-center">
                    <svg className="w-6 h-6 p-12 mb-4 text-gray-800  rounded-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
                    </svg>
                    <button onClick={handleFacebookLogin} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Login with Facebook</button>
                </div>
            </div>
        );
    }

    const pagesDropdown = () => {
        return (
            <div className="flex justify-center items-center mx-4 mt-4 h-full">
                <div className="flex flex-col items-center">
                    <select onChange={handleOnPageSelect} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                        <option value="" disabled selected>Select Desired Facebook Page</option>
                        {pages.length > 0 && pages.map((page: any) => {
                            return (
                                <option value={`${page.id},${page.access_token}`}>{page.name}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    }

    const pageFormsDropdown = () => {
        return (
            <div className="flex justify-center items-center mt-4 ">
                <div className="flex flex-col items-center">
                    <select onChange={handleOnPageFormSelect} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
                        <option value="" disabled selected>Select Page Form</option>
                        {pageForms.length > 0 && pageForms.map((pageForm: any) => {
                            return (
                                <option value={`${pageForm.id},${pageForm.name}`}>{pageForm.name} | Form ID: {`(` + pageForm.id + `)`}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    }


    if (isFacebookDataLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        );
    }

    const subcribeToFacebookApps = () => {
        const page_id = sourceFormValues.page_id;
        const page_access_token = sourceFormValues.page_access_token;
        window.FB.api('/' + page_id + '/subscribed_apps', 'post',
            { access_token: page_access_token, subscribed_fields: ['leadgen'] },
            (response: any) => {
                console.log('Successfully subscribed page', response);
            }
        );
    }

    const handleButtonClick = () => {
        setIsFacebookDataLoading(true);
        subcribeToFacebookApps();
        const payload = {
            ...sourceFormValues,
            user_id: user_id
        }
        createSourceIntegration(payload).unwrap().then((data: any) => {
            setErrorMessage("");
            setIsFacebookDataLoading(false);
            navigate(`/integrations/${sourceFormValues.integration_id}/destination`);

        }).catch((err) => {
            console.log(err);
            setIsFacebookDataLoading(false);
            if (err.status === 409) {
                setErrorMessage("This integration already exists");
            } else {
                setErrorMessage("Something went wrong. Could not add source! Try again later");
            }
        });
    }

    return (
        <div className="mt-4">
            <h1 className="text-center text-2xl">Add Data Source</h1>
            {errorMessage && <div className="text-red-500 text-center">{errorMessage}</div>}
            <div className="flex justify-center">
                {isLoggedIntoFacebook && pageLoading ?
                    <div className="flex justify-center items-center h-full">
                        <Loading />
                    </div> :
                    isLoggedIntoFacebook && pages.length > 0 && pagesDropdown()}

                {isLoggedIntoFacebook && pageLoading ?
                    <div className="flex justify-center items-center h-full">
                        <Loading />
                    </div> :
                    isLoggedIntoFacebook && pageForms.length > 0 && pageFormsDropdown()}
            </div>
            {isFormSelected && <div className="flex justify-center items-center mt-4">
                <OxButton value="Add Source" onClickHandler={() => handleButtonClick()}
                    customClasses="bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" />
            </div>
            }
            {!isLoggedIntoFacebook && facebookLoginBtn()}
        </div>
    );
}

export default ConnectSource;