import React from "react";

interface ToggleProps {
    onToggleSwitch: () => void;
    isLive: boolean;
}

const ToggleBtn = ({ onToggleSwitch, isLive }: ToggleProps) => {
    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" value="Live" onChange={onToggleSwitch} checked={isLive} />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
            </div>
        </label>
    )
}
export default ToggleBtn;