import React, { useState } from 'react';
import { useGetIntegrationFieldsQuery } from '../../services/integrations';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import { RootState } from "../../state/store";
import OxButton from "../../components/Ui/button";
import { useSavingMappingMutation, useDeleteMappingMutation, useGetIntegrationMappingsQuery } from '../../services/mappings';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';


type Field = {
    key: string;
    label: string;
};


type FieldMapping = {
    destination_field: string
    id: number
    integration_id: number
    source_field: string
    source_field_key: string
};


const Mappings = () => {
    const [selectedFbValue, setSelectedFbValue] = useState<Field>({} as Field);
    const [selectedAcsValue, setSelectedAcsValue] = useState<Field>({} as Field);;
    const [linkError, setLinkError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isMappingLinking, setIsMappingLinking] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    const { id: integrationId } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const sourceFormValues = useSelector((state: RootState) => state.integrationsReducer.sourceFormValues);
    const { data: mappings, refetch: refetchMappings, isFetching: isFetchingMappings } = useGetIntegrationMappingsQuery(sourceFormValues.integration_id)
    const { data: integrationFields, isFetching: isFetchingFields } = useGetIntegrationFieldsQuery(integrationId);
    const [saveMapping] = useSavingMappingMutation({})
    const [deleteMapping] = useDeleteMappingMutation({})

    const sourceFields = integrationFields?.source_fields;
    const destinationFields = integrationFields?.destination_fields;

    const checkMappingExists = (selectedFbValue: Field, selectedAcsValue: Field) => {
        const mappingExists = mappings?.find((mapping: FieldMapping) => {
            return mapping.source_field_key === selectedFbValue.key || mapping.destination_field === selectedAcsValue.key;
        });
        if (mappingExists) {
            setLinkError('Mapping already exists!');
            setIsSaveButtonDisabled(true);
            return true;
        } else {
            setIsSaveButtonDisabled(false);
            return false
        }
    }
    const linkFields = async () => {
        setLinkError('');
        setIsMappingLinking(true);
        if (!selectedFbValue || !selectedAcsValue) {
            setLinkError('Please select a field from both dropdowns');
            setIsMappingLinking(false);
        }
        const mappingExists = checkMappingExists(selectedFbValue, selectedAcsValue);
        if (mappingExists) {
            setIsMappingLinking(false);
            return
        };
        const mappings = {
            source_field: selectedFbValue.label,
            destination_field: selectedAcsValue.key,
            integration_id: integrationId,
            source_field_key: selectedFbValue.key
        }

        await saveMapping(mappings).unwrap().then((data: any) => {
            refetchMappings();
            setLinkError('');
            setIsMappingLinking(false);
        }).catch((err) => {
            setLinkError('Error mapping fields!')
            setIsMappingLinking(false);
        });
    };

    const unlinkField = async (mapping_id: number) => {
        setLinkError('');
        setIsMappingLinking(true);
        await deleteMapping(mapping_id).unwrap().then((data: any) => {
            refetchMappings();
            setLinkError('');
            setIsMappingLinking(false);
        }).catch((err) => {
            console.log(err)
            setLinkError('Error unlinking field')
            setIsMappingLinking(false);
        }
        );
    };

    const handleSourceSelection = (field: any) => {
        setSelectedFbValue({ key: field.value, label: field.label });
    }
    const handleDestinationSelection = (field: any) => {
        setSelectedAcsValue({ key: field.value, label: field.label });
    }

    const handleTestButtonClick = () => {
        setIsLoading(true);
        navigate(`/integrations/${sourceFormValues.integration_id}/mappings/test`);
        setIsLoading(false);
    }


    if (isFetchingFields || isLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        );
    }

    return (
        <div className="container my-4">
            <div className="flex flex-col justify-between items-even">
                <h3 className="mb-3">Field Mappings</h3>
                <p className="mb-4">Map Facebook fields to Adobe ACS fields.</p>
                <div className="flex flex-row items-center">
                    <div className="mr-3 basis-1/3">
                        <Select
                            placeholder="Select Facebook Field"
                            options={sourceFields} onChange={handleSourceSelection} />
                    </div>
                    <div className="mx-3 basis-1/3">
                        <Select
                            placeholder="Select ACS Field"
                            options={destinationFields} onChange={handleDestinationSelection} />
                    </div>
                    <div className="basis-1/3 ">
                        <OxButton customClasses="bg-blue-700 text-[#000]" value="Map Fields" onClickHandler={linkFields} />
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4">
                <p className="text-danger text-xl">{linkError}</p>
            </div>
            <table className="table table-bordered">
                <thead className='table-dark'>
                    <tr>
                        <th className="py-2 bg-cyan-800 text-center w-1/3">Facebook Field</th>
                        <th className="py-2 bg-cyan-800 text-center  w-1/3">Adobe ACS Field</th>
                        <th className="py-2 bg-cyan-800 text-center  w-1/3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {isMappingLinking || isFetchingMappings ?
                        <tr>
                            <td colSpan={3} className="text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                                    <Loading />
                                </div>
                            </td>
                        </tr>
                        :
                        mappings && mappings.map((mapping: FieldMapping) => {
                            return <tr className="text-center" key={mapping.id} data-id={mapping.id}>
                                <td>{mapping.source_field}</td>
                                <td>{mapping.destination_field}</td>

                                <td>
                                    <button className="btn btn-secondary" onClick={() => unlinkField(mapping.id)}>
                                        Remove Mapping
                                    </button>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            <div className="flex justify-center mt-4">
                <OxButton customClasses="bg-blue-700 text-[#000]" value="Test Mapping" onClickHandler={handleTestButtonClick} />
            </div>
        </div>
    );
}

export default Mappings;