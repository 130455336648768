import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SignIn from './pages/Auth/SignIn';
import Home from './pages/Home';
import { security } from './security';
import Loading from './components/loading';
import LeadsPage from './pages/Leads/Index';
import ConnectSource from './pages/Integrations/ConnectSource';
import AddDestination from './pages/Integrations/ConnectDestination';
import NewIntegrationForm from './pages/Integrations/NewIntegration';
import Mappings from './pages/Mappings/Index';
import TestMappingForm from './pages/Mappings/TestMappingsForm';
import AudienceForm from './pages/Audiences/AudienceForm';
import ListAudiences from './pages/Audiences/Audiences';
import ListCharts from './pages/Charts/Charts';
import ChartsForm from './pages/Charts/ChartForm';
import ViewChart from './pages/Charts/ChartView';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mediator from './pages/Audiences/Mediator';
import InviteCollaborator from './pages/Integrations/Invite';
import AcceptInvitation from './pages/Integrations/InviteAccept';
import Layout from './components/Layout/AppLayout';
import SignUp from './pages/Auth/Signup';
import VerifyAuth0Callback from './pages/Auth/VerifyLogin';
import { useSelector } from 'react-redux';
import { RootState } from './state/store';
import VerifyAuthCodeCallback from './pages/Auth/VerifyLogin/Code';

export const App = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  // set getAccessTokenSilently in global module to reuse it outside a React component
  security.setAccessTokenSilently(getAccessTokenSilently);

  const { logout, isLoading } = useAuth0();
  const isAuthenticated = useSelector((state: RootState) => state.usersReducer.isAuthenticated);


  if (isLoading) {
    return <div className="flex justify-center items-center h-[100vh]">
      <Loading />
    </div>;
  }

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/invite/:token" element={<AcceptInvitation />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/callback" element={<VerifyAuth0Callback />} />
        <Route path="/callback/sso" element={<VerifyAuthCodeCallback />} />

        <Route path="/login" element={<SignIn />} />
        {isAuthenticated ?
          <Route path="/" element={<Layout logout={logout} />}>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/integrations/new" element={<NewIntegrationForm />} />
            <Route path="/integrations/:id/source" element={<ConnectSource />} />
            <Route path="/integrations/:id/destination" element={<AddDestination />} />
            <Route path="/integrations/:id/leads" element={<LeadsPage />} />
            <Route path="/integrations/:id/mappings" element={<Mappings />} />
            <Route path="/integrations/:id/mappings/test" element={<TestMappingForm />} />
            <Route path="/integrations/invite" element={<InviteCollaborator />} />
            <Route path="/audiences" element={<ListAudiences />} />
            <Route path="/audiences/new" element={<AudienceForm />} />
            <Route path="/audiences/:id" element={<AudienceForm />} />
            <Route path="/audiences/:id/mediator" element={<Mediator />} />
            <Route path="/charts/new" element={<ChartsForm />} />
            <Route path="/charts/:id/edit" element={<ChartsForm />} />
            <Route path="/charts" element={<ListCharts />} />
            <Route path="/charts/:id/view" element={<ViewChart />} />
          </Route>
          :
          <Route path="*" element={<SignIn />} />
        }
      </Routes>
    </Router>
  )
}

export default App;