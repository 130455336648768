import React, { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { disconnectUser, setAuthenticatedUser } from '../../../state/users/slice';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "../../../components/loading";


const VerifyAuthCodeCallback = () => {
    const [error, setError] = React.useState<string | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0();



    useEffect(() => {
        const sendTokenToBackend = async () => {
          const tokenClaims = await getIdTokenClaims();
          const accessToken = await getAccessTokenSilently();
          try {
            if (isAuthenticated) {
              const { email, given_name, family_name } = tokenClaims || {};
              await axios.post(`${BASE_URL}/users/auth`, {
                email: email,
                auth0_id: tokenClaims?.sub,
                first_name: given_name,
                last_name: family_name
              }, {
                headers: {
                  Authorization: `Bearer ${accessToken}`
                }
              }).then((response) => {
                dispatch(setAuthenticatedUser({
                    user_id: response.data.user_id,
                    email: email,
                    isAuthenticated: true
                }));
                navigate('/dashboard');
              }).catch((error) => {
                console.error(error);
                disconnectUser()
                logout();

              });
            }
          } catch (error) {
            console.error("Axios error happened", error);
            setError("Error logging in");
          }
        };
        sendTokenToBackend();
      }, [isAuthenticated, getIdTokenClaims]);
    


    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <span className="text-red-500">{error}</span>
            </div>
        )
    }

    return (
        <div className="flex justify-center items-center h-screen">
            <Loading />
        </div>
    );
};

export default VerifyAuthCodeCallback;