// Need to use the React-specific entry point to allow generating React hooks
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { security } from "../security";
import { BASE_URL } from "../types";


export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: async (headers) => {
    const promise = await security.getAccessTokenSilently();
    const access_token = await promise();
    if (access_token) {
      headers.set("Authorization", `Bearer ${access_token}`);
    }
    return headers;
  },
});


export const api = createApi({
  baseQuery: baseQuery,
  endpoints: (builder) => ({}),
  reducerPath: "api",
  refetchOnMountOrArgChange: true,
  tagTypes: ["Integrations", "Mappings", "Audiences", "Leads", "Charts"],
});