import React, { useState } from "react";
import Tooltip from "../Ui/tooltip";
import ToggleBtn from "../Ui/toggle";
import { BASE_URL, Integration, Lead } from "../../types";
import Loading from '../../components/loading';
import {
    useGetIntegrationsQuery,
    useDeleteIntegrationMutation,
    useSyncIntegrationMutation,
    useToggleLiveIntegrationMutation,
} from "../../services/integrations";
import { useNavigate } from "react-router-dom";
import { security } from "../../security";

type IntegrationsTableProps = {
    integrations: Integration[];
    isIntegrationFetching: boolean;
    refetchIntegrations: () => void;
}

const IntegrationsTable = ({ integrations, isIntegrationFetching, refetchIntegrations }: IntegrationsTableProps) => {
    const [deleteIntegration, { isLoading: isLoadingDelete }] = useDeleteIntegrationMutation({})
    const [syncIntegration, { isLoading: isLoadingSync }] = useSyncIntegrationMutation({})
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [toggleLiveMode] = useToggleLiveIntegrationMutation({})
    const navigate = useNavigate();

    const onToggleSwitch = (integration: Integration) => {
        toggleLiveMode(integration.id).unwrap().then((res) => {
            refetchIntegrations()
        }).catch((err) => {
            console.log(err);
        })
    }

    const datetimeToHumanReadable = (datetime: string) => {
        const date = new Date(datetime);
        return date.toDateString();
    }
    const handleViewLeads = (integrationId: number) => {
        navigate(`/integrations/${integrationId}/leads`);
    }

    const handleDownload = async (integrationID: number) => {
        setIsLoadingDownload(true);
        try {
            // Use fetch to download the file
            const promise = await security.getAccessTokenSilently();
            const access_token = await promise();
            fetch(`${BASE_URL}/integrations/${integrationID}/leads/download`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${access_token}`
                },
                body: JSON.stringify({}),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    let dateTimeNow = new Date().toISOString();
                    link.setAttribute('download', `leads_${dateTimeNow}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                    setIsLoadingDownload(false);
                });
        } catch (error) {
            console.log(error);
            setIsLoadingDownload(false);
        }
    }

    const handleDeleteIntegration = (integration: Integration) => {
        deleteIntegration(integration.id).unwrap().then((res) => {
            refetchIntegrations()
            console.log(res);
        }
        ).catch((err) => {
            console.log(err);
        })
    }

    const handleSync = (integrationId: number) => {
        syncIntegration(integrationId).unwrap().then((data) => {
            setErrorMessage("");
            refetchIntegrations();
        }).catch((err) => {
            console.log(err);
            setErrorMessage("Something went wrong. Could not add source! Try again later");
        });
    }

    if (isLoadingDelete || isLoadingSync || isIntegrationFetching || isLoadingDownload) {
        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
            <Loading />
        </div>
    }

    return (
        <div className="container">
            <div className="table-responsive w-full">
                {errorMessage && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline">{errorMessage}</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => setErrorMessage('')} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <title>Close</title>
                            <path
                                fillRule="evenodd"
                                d="M14.348 5.652a.5.5 0 010 .707L10.707 10l3.64 3.64a.5.5 0 11-.707.707L10 10.707l-3.64 3.64a.5.5 0 11-.707-.707L9.293
                            10 5.652 6.36a.5.5 0 01.707-.707L10 9.293l3.64-3.64a.5.5 0 01.708 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </div>}
                <table className="table table-striped table-borderless w-full text-center">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Role</th>
                            <th>Connector Name</th>
                            <th> Is Live</th>
                            <th>Source</th>
                            <th>Destination</th>
                            <th># Leads</th>
                            <th>Pull Latest Leads</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {integrations && integrations.length > 0 ? (
                            integrations.map((integration: Integration, index: number) => (
                                <tr key={integration.id} className="text-center items-center">
                                    <th>{index + 1}</th>
                                    <th>{integration.user_type}</th>
                                    <td>{integration.name}</td>
                                    <td>

                                        <div className="flex justify-center items-center">
                                            <ToggleBtn onToggleSwitch={() => onToggleSwitch(integration)} isLive={integration.is_live} />
                                        </div>
                                    </td>
                                    <td className="">
                                        {integration.source}
                                    </td>
                                    <td className="">
                                        {integration.destination}
                                    </td>

                                    <td>{integration?.leads_count ? integration.leads_count : 0}</td>
                                    <td>
                                        {
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                                                onClick={() => handleSync(integration.id)}>
                                                Pull Now
                                            </button>
                                        }
                                    </td>
                                    <td>
                                        {datetimeToHumanReadable(integration.created_at)}
                                    </td>
                                    <td colSpan={4} className="flex justify-evenly items-center">
                                        <div>
                                            <Tooltip content="View Leads">
                                                <a onClick={() => handleViewLeads(integration.id)} data-tooltip-target="view-tooltip" data-tooltip-style="dark" data-tooltip-trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>
                                                </a>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip content="Download Leads">
                                                <a onClick={() => handleDownload(integration.id)} data-tooltip-target="download-tooltip" data-tooltip-style="dark" data-tooltip-trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                    </svg>
                                                </a>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            {integration.user_type == "owner" && <Tooltip content="Delete Integration">
                                                <a onClick={() => handleDeleteIntegration(integration)} data-tooltip-target="delete-tooltip" data-tooltip-style="dark" data-tooltip-trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </a>
                                            </Tooltip>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} className="text-center text-2xl p-3">
                                    No Integrations Available! <br />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default IntegrationsTable;