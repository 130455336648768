import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth0Client } from "../../../custom-auth0";
import { disconnectUser, setAuthenticatedUser } from '../../../state/users/slice';
import { Auth0UserProfile } from "auth0-js";
import Loading from "../../../components/loading";


type TokenClaims = {
    email: string;
    given_name: string;
    family_name: string;
    sub: string;
};


const getUserInfo = async (sendTokenToBackend: (user: Auth0UserProfile, accessToken?: string) => void) => {
    const auth0 = await getAuth0Client();
    auth0.parseHash({ hash: window.location.hash }, function (err, authResult) {
        if (err || !authResult || !authResult.accessToken) {
            console.error(err || 'Error parsing the authentication result.');
            return;
        }
        
        auth0.client.userInfo(authResult.accessToken, function (err, user) {
            if (err) {
                console.error('Error retrieving user info:', err);
                return;
            }
            sendTokenToBackend(user, authResult.accessToken);
        });
    });
}


const logout = async () => {
    const auth0 = await getAuth0Client();
    auth0.logout({
        returnTo: window.location.origin
    });
}

const VerifyAuth0Callback = () => {
    const [error, setError] = React.useState<string | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        const sendTokenToBackend = async (userProfile: Auth0UserProfile, accessToken?: string | undefined) => {

            if (!userProfile.email) {
                console.error("User profile does not include an email.");
                return;
            }
        
            const tokenClaims: TokenClaims = {
                email: userProfile.email,
                given_name: userProfile.given_name || '', 
                family_name: userProfile.family_name || '', 
                sub: userProfile.sub
            };
            try {
                const { email, given_name, family_name } = tokenClaims || {};
                await axios.post(`${BASE_URL}/users/auth`, {
                    email: email,
                    auth0_id: tokenClaims?.sub,
                    first_name: given_name,
                    last_name: family_name
                }, { 
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }).then((response) => {
                    dispatch(setAuthenticatedUser({
                        user_id: response.data.user_id,
                        email: email,
                        isAuthenticated: true
                    }));
                    window.location.href = '/dashboard';
                }).catch((error) => {
                    console.error(error);
                    disconnectUser()
                    logout()
                });
            } catch (error) {
                console.error("Axios error happened", error);
                setError("Error logging in");
            }
        };
        getUserInfo(sendTokenToBackend);
    }, []);


    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <span className="text-red-500">{error}</span>
                <div onClick={() => navigate("/login")} className="">Back To Login</div>
            </div>
        )
    }

    return (
        <div className="flex justify-center items-center h-screen">
            <Loading />
        </div>
    );

};

export default VerifyAuth0Callback;