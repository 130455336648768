import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { security } from "../../security";
import Loading from "../../components/loading";


const generateRandomNumbersTuple = (numberOfLabels: number) => {
    const randomNumbers = [];
    for (let i = 0; i < numberOfLabels; i++) {
        const random = Math.floor(Math.random() * 100);
        randomNumbers.push(random);
    }
    return randomNumbers.join(',');
}


const ViewChart = () => {
    const [isLoading, setIsLoading] = useState(true);
    // Get state from the route
    const { state } = useLocation()
    const { chart } = state
    const { labels } = chart
    const labelsLength = labels.split(',').length
    const { id } = useParams()
    const chartId = id
    const values = generateRandomNumbersTuple(labelsLength)
    const [currentUrl, setCurrentUrl] = useState('');

    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const fetchImage = async () => {
            const url = `${process.env.REACT_APP_SERVER_API_URL}/api/v1/charts/${chartId}/image?values=${values}`;
            try {
                const promise = await security.getAccessTokenSilently();
                const access_token = await promise();
                setCurrentUrl(url);
                const response = await fetch(url, {
                    headers: {
                        "Authorization": `Bearer ${access_token}`
                    }
                });
                const imageBlob = await response.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImageSrc(imageObjectURL);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
        setIsLoading(false);
    }, []);


    if (isLoading) {
        return <div className="flex justify-center items-center h-[100vh]">
            <Loading />
        </div>;
    }


    return (
        <div className="flex justify-center items-center flex-col">
            {imageSrc && <img src={imageSrc} alt="chart" />}
            <br />
            <div className="text-center">
                Chart Image Link: <a href={currentUrl} target="_blank" className="text-blue-500" >{currentUrl}</a>
            </div>
        </div>
    )
}

export default ViewChart;