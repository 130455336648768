import React, { useState } from "react";
import Tooltip from "../../components/Ui/tooltip";
import { useNavigate } from "react-router-dom";
import OxButton from "../../components/Ui/button";
import { useDeleteAudienceMutation, useGetAudiencesQuery } from "../../services/audiences";
import Loading from "../../components/loading";


const ListAudiences = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const { data: audiences, refetch: refetchAudience, isFetching: isFetchingAudience } = useGetAudiencesQuery({});
    const [deleteAudience, { isLoading: isLoadingDelete }] = useDeleteAudienceMutation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/audiences/new')
    }

    const handleDeleteAudience = (id: number) => {
        deleteAudience(id).unwrap().then((data: any) => {
            refetchAudience();
        }).catch((err) => {
            setErrorMessage("Something went wrong. Could not delete audience! Try again later");
        });
    }
    const handleViewAudience = (audience: any) => {
        navigate(`/audiences/${audience.id}/mediator`, {
            state: { audience }
        })
    }

    const handleEditAudience = (audience: any) => {
        navigate(`/audiences/${audience.id}`, {
            state: { audience }
        })
    }


    if (isFetchingAudience || isLoadingDelete) {
        return <div className="flex justify-center items-center h-[100vh]">
            <Loading />
        </div>
    }

    return (
        <div className="container mt-4 mx-auto">
            <div className="flex justify-between flex-row">
                <div className="text-3xl">
                   Custom Audiences
                </div>
                <div>
                    <OxButton value={"Create New Custom Audience"} onClickHandler={handleButtonClick} />
                </div>
            </div>
            <hr className="h-px my-2 bg-gray-200 border-0" />
            <div className="table-responsive w-full">
                {errorMessage && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline">{errorMessage}</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg onClick={() => setErrorMessage('')} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <title>Close</title>
                            <path
                                fillRule="evenodd"
                                d="M14.348 5.652a.5.5 0 010 .707L10.707 10l3.64 3.64a.5.5 0 11-.707.707L10 10.707l-3.64 3.64a.5.5 0 11-.707-.707L9.293
                    10 5.652 6.36a.5.5 0 01.707-.707L10 9.293l3.64-3.64a.5.5 0 01.708 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </div>}
                <table className="table table-striped table-borderless w-full text-center">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Custom Audience Name</th>
                            <th>Description</th>
                            <th>FB Business Account ID</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audiences && audiences.length > 0 ? (
                            audiences.map((audience: any, index: number) => (
                                <tr key={audience.id} className="text-center items-center">
                                    <th>{index + 1}</th>
                                    <td>{audience.name}</td>
                                    <td className="">
                                        {audience.description}
                                    </td>
                                    <td className="">
                                        {audience.fb_business_id}
                                    </td>
                                    <td colSpan={4} className="flex justify-evenly items-center">
                                        <div>
                                            <Tooltip content="View API">
                                                <a onClick={() => handleViewAudience(audience)} data-tooltip-target="download-tooltip" data-tooltip-style="dark" data-tooltip-trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                                                    </svg>

                                                </a>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip content="Edit Audience">
                                                <a onClick={() => handleEditAudience(audience)} data-tooltip-target="download-tooltip" data-tooltip-style="dark" data-tooltip-trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                    </svg>

                                                </a>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip content="Delete Integration">
                                                <a onClick={() => handleDeleteAudience(audience.id)} data-tooltip-target="delete-tooltip" data-tooltip-style="dark" data-tooltip-trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className="text-center text-2xl p-3">
                                    No Custom Audiences Available! <br />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListAudiences;