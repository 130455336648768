import React from "react";

interface ACSDestinationFormProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ACSDestinationForm = ({handleChange}: ACSDestinationFormProps) => {
    return (
        <div className="flex flex-col items-center text-center">
            <form className="flex flex-col w-full items-center">
                <div className="flex flex-row w-1/2 mb-4">
                    <div className="flex flex-col text-left w-full">
                        <label className="text-lg">ACS Tenant ID *</label>
                        <input name="acs_tenant_id" onChange={handleChange} className="border border-gray-400 rounded-md h-8" required/>
                    </div>
                </div>
                <div className="flex flex-row w-1/2 mb-4">
                    <div className="flex flex-col text-left w-full">
                        <label className="text-lg">ACS Client ID *</label>
                        <input name="acs_client_id" onChange={handleChange} className="border border-gray-400 rounded-md h-8" required/>
                    </div>
                </div>
                <div className="flex flex-row w-1/2">
                    <div className="flex flex-col text-left w-full">
                        <label className="text-lg">ACS Client Secret *</label>
                        <input name="acs_client_secret" onChange={handleChange} className="border border
                        -gray-400 rounded-md h-8" required/>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ACSDestinationForm;