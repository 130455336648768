import React from "react";

// Add svg icons as props
interface ButtonProps { 
    onClickHandler?: () => void;
    customClasses?: string;
    value: string;
    disabled?: boolean;
    icon?: any;
}

const OxButton = ({onClickHandler, customClasses, value, disabled, icon }: ButtonProps) => {
    if (disabled === undefined) disabled = false;
    const isDisabled = disabled ? 'opacity-50 cursor-not-allowed' : ''; 
    return (
        <div>
            {icon && <span className="me-2">{icon}</span>}
            <button onClick={onClickHandler} className={`${customClasses} ${isDisabled} text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`} disabled={disabled}>{value}</button>
        </div>
    )
}
export default OxButton