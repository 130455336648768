import React, { ChangeEvent, useState } from 'react';
import { useGetIntegrationsQuery, useInviteCollaboratorMutation } from '../../services/integrations';
import { toast } from 'react-toastify';

const InviteCollaborator = () => {
    const [successMessage, setSuccessMessage] = useState("")
    const [formState, setFormState] = useState({
        email: "",
        firstName: "",
        lastName: ""
    })
    const [inviteCollaborator, { isLoading, isError, isSuccess }] = useInviteCollaboratorMutation();

    const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };
    
    const handleSendInvite = async () => {
        const payload = {
            email: formState.email,
            first_name: formState.firstName,
            last_name: formState.lastName
        }
        await inviteCollaborator(payload).unwrap().then(() => {
            setFormState({
                email: "",
                lastName: "",
                firstName: ""
            })
            setSuccessMessage(`An invitation email has been sent to ${formState.email}`)
        }).catch(() => {
            setFormState({
                email: "",
                lastName: "",
                firstName: ""
            })
        });
    }

    if (isError) {
        toast.error(`Failed to Invite Collaborator. ${formState.email}`);
    }

    return (
        <>
            <div>
            <span className="flex justify-center items-center my-5 text-green-500 text-xl text-center font-medium">{successMessage}</span>
            </div>
            <div className="flex justify-center items-center">
                <div className="flex flex-col w-1/3">
                    <div className="text-3xl text-center">Invite Colaborator</div>
                    <div className="flex flex-col mt-4">
                        <label className="text-lg">Collaborator First Name</label>
                        <input 
                            onChange={handleFormChange}
                            name="firstName"
                            type="text"
                            value={formState.firstName}
                            className="border-2 border-gray-300 rounded-md p-2" />
                    </div>
                    <div className="flex flex-col mt-4">
                        <label className="text-lg">Collaborator Last Name</label>
                        <input 
                            onChange={handleFormChange}
                            name="lastName"
                            type="text"
                            value={formState.lastName}
                            className="border-2 border-gray-300 rounded-md p-2" />
                    </div>
                    <div className="flex flex-col mt-4">
                        <label className="text-lg">Collaborator Email Address</label>
                        <input
                            onChange={handleFormChange}
                            name="email"
                            type="email"
                            value={formState.email}
                            className="border-2 border-gray-300 rounded-md p-2" />
                    </div>
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={handleSendInvite}
                            type='button'
                            className="bg-blue-500 text-white rounded-md py-2 px-4">
                            {isLoading ? "Loading..." : "Invite Collaborator"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default InviteCollaborator;
