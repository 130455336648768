// Layout.js
import React from 'react';
import MainMenu from '../Menus/MainMenu';
import Sidebar from '../Menus/SideBar';
import { Outlet } from 'react-router-dom';

type LayoutProps = {
    logout: () => void;
};

const Layout = ({ logout }: LayoutProps) => {
    return (
      <div className="flex">
        <div className="basis-1/5">
          <Sidebar />
        </div>
        <div className="basis-4/5">
          <MainMenu logout={logout} />
          <Outlet />
        </div>
      </div>
    );
  };
  
  export default Layout;
