import { createSlice } from "@reduxjs/toolkit";

interface Integration {
  id: number;
  name: string;
  source: string;
  destination: string;
  leads: number;
  isLive: boolean;
  isSync: boolean;
  createdAt: Date;
}

interface initialStateType {
  integrations?: Array<Integration>;
  status?: "success" | "loading" | "failed" | "idle";
  step: number;
  sourceFormValues: {
    page_access_token?: string;
    fb_user_id?: string;
    fb_user_access_token?: string;
    page_id?: string;
    form_id?: string;
    form_name?: string;
    integration_id?: number;
  };
}

const initialState: initialStateType = {
  integrations: [],
  status: "idle",
  step: 0,
  sourceFormValues: {
    page_access_token: "",
    fb_user_access_token: "",
    fb_user_id: "",
    page_id: "",
    form_id: "",
    form_name: "",
    integration_id: undefined,
  }
};

const integrationsSlice = createSlice({
  name: "integrations",
  initialState: initialState,
  reducers: {
    incrementStep: (state) => {
      state.step += 1;
    },
    decrementStep: (state) => {
      state.step -= 1;
    },
    setSourceIntegrationValues: (state, action) => {
        state.sourceFormValues = {...state.sourceFormValues, ...action.payload}
    },
    resetStep: (state) => {
      state.step = 0;
    },
  },
});
export const {
     incrementStep, 
     decrementStep, 
     resetStep,
     setSourceIntegrationValues,
} = integrationsSlice.actions;
export default integrationsSlice.reducer;
