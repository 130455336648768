import { PayloadAction, createSlice } from "@reduxjs/toolkit";


interface User {
    user_id?: number;
    name?: string;
    email?: string;
    isAuthenticated: boolean;
    accessToken?: string;
}

const initialUserState: User = {
    user_id: undefined,
    name: "",
    email: "",
    isAuthenticated: false,
    accessToken: ""
}

const usersSlice = createSlice({
    name: "users",
    initialState: initialUserState,
    reducers: {
        setAuthenticatedUser: (state, action: PayloadAction<User>) => {
            const { user_id, isAuthenticated, accessToken } = action.payload
            state.isAuthenticated = isAuthenticated
            state.user_id = user_id;
            state.accessToken = accessToken
        },
        disconnectUser: (state) => {
            state.user_id = undefined
            state.isAuthenticated = false
            state.accessToken = ""
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        }
    }
})

export const { setAuthenticatedUser, setAccessToken, disconnectUser } = usersSlice.actions;
export default usersSlice.reducer;