import React from "react";
import storage from "redux-persist/lib/storage";
import { disconnectUser } from "../../state/users/slice";


interface MainMenuProps {
    logout: () => void;
}

const MainMenu = ({ logout }: MainMenuProps) => {

    const handleLogout = () => {
        storage.removeItem('persist:root');
        disconnectUser()
        logout();
    }
    return (
        <div className="flex bg-[#343a40] p-4">
            <div className="text-white text-xl ml-auto text-right">
                <button onClick={handleLogout}>Logout</button>
            </div>
        </div>
    )
}
export default MainMenu;