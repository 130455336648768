import React, { useEffect, useState } from 'react';
import { useGetLeadsDataQuery, usePushLeadMutation } from '../../services/integrations';
import { useParams } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import Loading from '../../components/loading';

// Define the data type
interface LeadItem {
    full_name: string;
    lead_id: string;
    form_id: string;
    form_name: string;
}

const LeadsPage = () => {

    const { id } = useParams();
    const [leadsData, setLeadsData] = useState([])
    const [errorMessage, setErrorMessage] = useState("");
    const { data, isFetching, refetch } = useGetLeadsDataQuery(id);
    const [pushLead, { isLoading: isPushLoading }] = usePushLeadMutation({});

    // Transform the data
    const columns = data?.columns ?? [];

    const columnDefs: ColumnDef<LeadItem>[] = columns
        .filter((column: string) => column !== 'Form Name' && column !== 'Form Id')
        .map((column: string, index: number) => {
            if (leadsData.length <= 0) {
                return {
                    id: column,
                    accessorKey: column,
                    header: column,
                    cell: (info: any) => info.getValue(),
                    footer: (props: any) => props.column.id,
                };
            }
            const accessor = Object.keys(leadsData[0])[index];

            // Add sync button for column name is_synced
            return {
                id: accessor,
                accessorKey: accessor,
                header: column,
                cell: (info: any) => {
                    if (info.column.id === 'Sync Status' && info.getValue() === 'Sync Now') {
                        return (
                            <div className="flex justify-center">
                                <button
                                    onClick={() => handlePushData(info.row.original["Lead ID"])}
                                    className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-1 rounded-full"
                                >
                                    {info.getValue()}
                                </button>
                            </div>
                        );
                    }
                    if (info.column.id === 'Sync Status' && info.getValue() === 'Synced') {
                        return (
                            <div className="flex justify-center">
                                <span className="text-white px-4 py-1 rounded-full bg-green-500">{info.getValue()}</span>
                            </div>
                        );
                    }

                    return info.getValue();
                },
            };
        });

    const leads = data?.leads;
    const table = useReactTable({
        data: leadsData,
        columns: columnDefs,
        getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {
        if (leads) {
            setLeadsData(leads);
        }
    }, [leads, isPushLoading]);

    const handlePushData = (lead_id: number) => {
        pushLead({ lead_id: lead_id, integration_id: id }).unwrap().then((res) => {
            setErrorMessage("");
            refetch();

        }).catch((err) => {
            console.log(err);
            setErrorMessage("Something went wrong. Could not push lead! Try again later");
        })
    }

    return (
        <div className="container mt-4">
            <h1 className="text-3xl font-semibold">Leads for  <span className="text-[blue]">{data?.form_name} </span>form</h1>
            {errorMessage && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{errorMessage}</div>}
            <table className="table table-auto w-full text-center">
                <thead>
                    {table?.getHeaderGroups()?.map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers?.map(header => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {isFetching || isPushLoading ? (
                        <tr>
                            <td colSpan={table?.getAllColumns()?.length} className="text-center">
                                <div className="flex justify-center items-center">
                                    <Loading />
                                </div>
                            </td>
                        </tr>
                    ) : table.getRowModel()?.rows?.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells()?.map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    )
}
export default LeadsPage;